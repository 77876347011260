import React, {useContext, useEffect, useState} from 'react';
import utils from "../../utils/utils";
import texts from "../../utils/texts";
import { TreeSelect } from 'antd';
import DatePicker from "react-datepicker";
import {UserContext} from "../../Contexts/UserContext";
import {FilterContext} from "../../Contexts/FilterContext";
import {ClassificationContext} from "../../Contexts/ClassificationContext";

const { SHOW_PARENT } = TreeSelect;

const Header = () => {

    const [dataTreeDropdown, setDataTreeDropdown] = useState([])
    const [valueDropdown, setValueDropdown] = useState([])
    const { username, settings, setSettings } = useContext(UserContext)
    const { brand, startDate, setStartDate, endDate, setEndDate, classification, setClassification, pointOfCollect, setPointOfCollect } = useContext(FilterContext)
    const { classifications, steps, setClassifications, setSteps} = useContext(ClassificationContext)

    const getTopicsAndClassifications = async function () {
        return new Promise((resolve, reject) => {
            utils.fetchSteps({
                brand,
                startDate,
                endDate,
                classification,
                poc: pointOfCollect
            }).then((data) => {
                resolve(data)
            }).catch(e => {
                console.log(e)
            })
        })
    }

    const getSettings = async function () {
        return new Promise((resolve, reject) => {
            utils.fetchSettings(username, brand).then(settings => {
                if (utils.mobileCheck() && settings['mobile']) {
                    settings = settings['mobile']
                }
                resolve(settings)
            })
        })
    }

    useEffect(() => {
        /**
         * Fetch settings only if brand changes
         */
        getSettings()
            .then(fetchedSetting => {
                if (fetchedSetting) {
                    setStartDate(endDate - (fetchedSetting['range'] || 1000 * 30 * 24 * 60 * 60))
                    setSettings(fetchedSetting)
                    utils.fetchPocTree(fetchedSetting['poc_tree'], brand).then(
                        x => {
                            console.log("🔴", x)
                            setDataTreeDropdown(x)
                        }
                    )
                }
            })
    }, [brand]);

    useEffect(() => {
        if (window.localStorage.getItem("poc")) {
            const local_value = JSON.parse(window.localStorage.getItem("poc"))
            try {
                const local_value_bis = local_value.map(
                    x => x.split(' >> ').filter(
                        y => !y.includes("[DELETE]")
                    )
                ).filter(
                    x => x.length > 0
                )
                setValueDropdown(local_value)
                setPointOfCollect(local_value_bis)
            } catch (e) {
                console.log(e)
            }
        }
        
    }, [dataTreeDropdown])

    useEffect(() => {
        if (brand) {
            const fetchData = async function () {
                const topicsAndClassifications = await getTopicsAndClassifications()
                setSteps(topicsAndClassifications.topics)

                if (!classification.length && topicsAndClassifications['topic_classes'] && topicsAndClassifications['topic_classes'].length > 0) {
                    setClassification(topicsAndClassifications['topic_classes'][0])
                    setClassifications(topicsAndClassifications['topic_classes'])
                }
            }
            fetchData()
        }
    }, [
        brand, startDate, endDate, classification, pointOfCollect
    ])

    const handleSelectedClassificationValueChange = function (e) {
        setClassification(e.target.value)
    }

    const manageDropdownTree = (value) => {
        console.log(' 🐞 ', dataTreeDropdown)
        setValueDropdown(value)
        const new_value = value.map(
            x => x.split(' >> ').filter(
                y => !y.includes("[DELETE]")
            )
        ).filter(
            x => x.length > 0
        )
        localStorage.setItem('poc', JSON.stringify(value))
        return setPointOfCollect(new_value)
    }

    return (
        <FilterContext.Consumer>
            {
                ({ classification }) => (
                    <header className="App-header">
                        <div className='title'>
                            <p className='titleDash'>{settings['title'] ? utils.getLanguage(settings['title']) : utils.getLanguage(texts['title'])}</p>
                            <div style={{
                                display: classifications && classifications.length ? '' : 'none',
                                lineHeight: '70px',
                                marginLeft: '30px'
                            }}>
                                <label style={{
                                    margin: 0,
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    padding: 0
                                }}>Classification : </label>
                                <select id="class_selector" value={classification}
                                        onChange={handleSelectedClassificationValueChange}
                                        style={{
                                            'borderColor': 'transparent',
                                            'outlineColor': 'transparent',
                                            'width': '150px',
                                        }}>
                                    {classifications.map(
                                        (val, id) => <option value={val} key={id}>{val}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div id='date_pickers'>
                            <div id='poc_selector' style={{display: settings.hasOwnProperty('poc_tree') ? '' : 'none'}}>
                                <TreeSelect
                                    treeData={dataTreeDropdown}
                                    value={valueDropdown}
                                    onChange={manageDropdownTree}
                                    treeCheckable={true}
                                    showCheckedStrategy={SHOW_PARENT}
                                    placeholder={utils.getLanguage({
                                        "en": "Filters ...",
                                        "fr": "Filtrer ..."
                                    })}
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </div>
                            <DatePicker showTimeSelect dateFormat="dd/MM/yyyy" className='date_picker' selected={startDate}
                                        onChange={date => setStartDate(new Date().getMilliseconds() + date.getTime())}/>
                            -
                            <DatePicker showTimeSelect dateFormat="dd/MM/yyyy" className='date_picker' minDate={startDate} selected={endDate}
                                        onChange={date => setEndDate(new Date().getMilliseconds() + date.getTime())}/>
                        </div>
                    </header>
                )
            }
        </FilterContext.Consumer>
    );
};

export default Header;
