import React, {useContext, useEffect, useRef, useState} from 'react';
import Chart from "chart.js";
import utils from "../../../utils/utils";
import texts from "../../../utils/texts";
import Spinner from "../../Shared/Spinner";
import {FilterContext} from "../../../Contexts/FilterContext";
import {ClassificationContext} from "../../../Contexts/ClassificationContext";

const CustomerJourney = ({ setSubFilters, subFilters }) => {
    const canvas = useRef(null);
    const [step, setStep] = useState('Tous')
    const [dataSteps, setDataSteps] = useState([])
    const [loading, setLoading] = useState(false)
    const { brand, startDate, endDate, classification, pointOfCollect } = useContext(FilterContext)
    const { steps } = useContext(ClassificationContext)
    let chart = null;

    useEffect(() => {
        if (dataSteps) {
            const ctx = canvas.current.getContext('2d')
            chart = new Chart(ctx, {
                type: 'bar',
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        labels: {
                            fontColor: 'rgb(150,150,150)'
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true
                        },
                        xAxes: [{
                            gridLines: {
                                color: 'rgb(90, 90, 90)',
                            },
                            scaleLabel: {
                                display: true,
                                labelString: classification.length > 0 ? classification : utils.getLanguage(texts['global']['Sujet'])
                            },
                            stacked: true,
                            barThickness: 30
                        }],
                        yAxes: [{
                            gridLines: {
                                color: 'rgb(90, 90, 90)',
                            },
                            stacked: true
                        }]
                    }
                },
                plugins: [{
                    beforeUpdate: function (chart) {
                        chart.data.labels.forEach(function (e, i, a) {
                            if (e.length > 30) {
                                const res = ['', '']
                                let line = 0
                                for (const l in e) {
                                    res[line] += e[l]
                                    if (e[l] === ' ' && l > e.length / 2 - 5 && line === 0) {
                                        line += 1
                                    }
                                }
                                a[i] = res
                            }
                        });
                    }
                }]
            });

            const conversion_step = {}
            for (const s of steps) {
                conversion_step[utils.parseTopicName(s)] = s
            }

            //let dataSteps = utils.aggregateFeedbackBySteps(aggregatedFeedbackData, steps)
            // console.log('Sentiment per topic graph : ', dataSteps)

            const reg = new RegExp('^' + (step === 'Tous' ? '' : step), "g");

            const filter_step = (x, step) => {
                const step_l = step === "Tous" ? "" : step + " > ";
                let l_step = step_l.length;
                return x['step_name'].substring(0, l_step) === step_l && !(x['step_name'].substring(l_step).includes(' > '))
            }

            let filteredSteps = dataSteps.filter(x => filter_step(x, step))

            let new_step = step

            while (filteredSteps.length <= 1 && dataSteps.length > 1) {
                new_step = new_step.includes(' > ') ? new_step.split(' > ').slice(0, new_step.split(' > ').length - 1).join(' > ') : "Tous";
                filteredSteps = dataSteps.filter(x => filter_step(x, new_step));
            }

            if (new_step !== step) {
                setStep(new_step)
            }

            if (!filteredSteps.length > 1) {
                filteredSteps = dataSteps.filter(x => filter_step(x, 'Tous'))
            }

            chart.data = {
                labels: filteredSteps.map((x) => utils.parseTopicName(x['step_name'])),
                datasets: [{
                    label: utils.getLanguage(texts['Visualisations']['Satisfaction des clients par étape de parcours']['Commentaires négatifs']),
                    backgroundColor: utils.gradients.red,
                    data: filteredSteps.map((x) => x['negative_comments_count'])
                },{
                    label: utils.getLanguage(texts['Visualisations']['Satisfaction des clients par étape de parcours']['Commentaires mitigés']),
                    backgroundColor: utils.gradients.orange,
                    data: filteredSteps.map((x) => x['mixed_comments_count'])
                },{
                    label: utils.getLanguage(texts['Visualisations']['Satisfaction des clients par étape de parcours']['Commentaires positifs']),
                    backgroundColor: utils.gradients.blue,
                    data: filteredSteps.map((x) => x['positive_comments_count'])
                },{
                    label: utils.getLanguage(texts['Visualisations']['Satisfaction des clients par étape de parcours']['Commentaires neutres']),
                    backgroundColor: utils.gradients.grey,
                    data: filteredSteps.map((x) => x['neutral_comments_count'])
                },]
            };
            chart.update()

            canvas.current.onclick = function (evt) {
                if (chart.getElementAtEvent(evt).length > 0 && chart.getElementAtEvent(evt)[0].hasOwnProperty('_model')) {
                    let step = chart.getElementAtEvent(evt)[0]['_model']['label']
                    if (typeof (step) == 'object') {
                        step = step.join('')
                    }
                    step = conversion_step[step]
                    if (step) {
                        const elem = document.getElementsByClassName('topic_select')
                        // console.log(elem)
                        for (const e of elem) {
                            e.classList.add('select_hilight')
                            e.onclick = () => {
                                e.classList.remove('select_hilight')
                            }
                        }
                        setSubFilters({
                            ...subFilters,
                            step
                        })
                    }
                }
            };

            return () => {
                if (chart !== null) {
                    chart.destroy()
                }
            };
        }
    }, [dataSteps, steps, step])

    useEffect(() => {
        if (subFilters.hasOwnProperty('step')) {
            setStep(subFilters['step'])
        }
    }, [subFilters])

    useEffect(() => {
        getSentimentPerTopic()
            .then(data => {
                let dataSteps = []
                const keys = Object.keys(data)
                if (keys.length) {
                    keys.filter(key => {
                        return data[key].positive !== undefined && data[key].negative !== undefined
                    }).map(key => {
                        dataSteps.push({
                            step_name: key,
                            positive_comments_count: data[key].positive,
                            negative_comments_count: data[key].negative,
                            mixed_comments_count: data[key].mixed,
                            neutral_comments_count: data[key].neutral
                        })
                    })
                    setDataSteps(dataSteps)
                }
            })
    }, [
        brand,
        startDate,
        endDate,
        classification,
        pointOfCollect,
    ])

    const getSentimentPerTopic = async () => {
        setLoading(true)
        const data =  await utils.fetchSentimentPerTopic({
            brand, startDate, endDate, classification, poc: pointOfCollect
        })
        setLoading(false)
        return data
    }

    return (
        <div className="card graph position-relative overflow-hidden">
            {
                loading && <Spinner/>
            }
            <p>{utils.getLanguage(texts['Visualisations']['Satisfaction des clients par étape de parcours']['title']) + (classification.length > 0 ? classification : utils.getLanguage(texts['global']['Sujet'])).toLowerCase()}</p>
            <div className="filters">
                <span>
                    {classification.length > 0 ? classification : utils.getLanguage(texts['global']['Sujet'])}  :
                    <select className="topic_select" id='sent_evol_flt' title={utils.parseTopicName(step)} value={step} onChange={e => setStep(e.target.value)}>
                    {
                        ['Tous'].concat(steps).map((val, id) =>
                            <option value={val} key={id}>{utils.parseTopicName(val)}</option>
                        )
                    }
                    </select>
                </span>
            </div>
            <div className="graph-canvas">
                <canvas id="customer_journey" ref={canvas}/>
            </div>
        </div>
    )
};

CustomerJourney.propTypes = {

};

export default CustomerJourney;
