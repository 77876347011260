import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../../Contexts/UserContext";
import utils from "../../../../utils/utils";
import { FilterContext } from "../../../../Contexts/FilterContext";

const FeedbackStatus = ({ feedback, setFeedback, feedbacks, setFeedbacks }) => {
  const { brand } = useContext(FilterContext);

  const status = [
    ...useContext(UserContext).settings.status,
    { label: "Aucun", color: "rgba(0, 0, 0, 0)" },
  ];

  const handleStatusChange = function (e) {
    const value = e.target.value;
    utils
      .fetchUpdateState({
        brand,
        id: feedback.id,
        state: value,
      })
      .then(() => {
        setFeedback({ ...feedback, ...{ state: value } });
        setFeedbacks(
          feedbacks.map((x) =>
            x.id === feedback.id ? { ...feedback, ...{ state: value } } : x
          )
        );
      });
  };

  feedback.state = feedback.state || "Aucun";
  return (
    <div
      style={{
        height: "20px",
      }}
    >
      <span
        className="pastille"
        style={{
          backgroundColor:
            status.filter((x) => x["label"] === feedback.state).length > 0 &&
            feedback.state !== "unProcessed"
              ? status.filter((x) => x["label"] === feedback.state)[0]["color"]
              : 0,
        }}
      />
      <select
        style={{
          border: "none",
          marginRight: "10px",
        }}
        onChange={handleStatusChange}
        value={feedback.state}
      >
        {status.map((x) => (
          <option value={x["label"]} key={x["label"]}>
            {x["label"]}
          </option>
        ))}
      </select>
    </div>
  );
};

FeedbackStatus.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default FeedbackStatus;
