import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import utils from "../../../../utils/utils";
import texts from "../../../../utils/texts";
import { UserContext } from "../../../../Contexts/UserContext";
import FeedbackSteps from "../FeedbackSteps";


const LastFeedbacks = ({ feedbacks, subFilters, setShownFeedback }) => {

    const { settings } = useContext(UserContext)

    const handleFeedbackClick = function (feedback) {
        setShownFeedback(feedback)
    }

    const manage_date = (date) => {
        date.setHours(date.getHours())
        return date
    }

    return (
        <div className="last_50">
            {
                feedbacks.map((feedback, id) =>
                    <div className="feedback" key={id} onClick={() => handleFeedbackClick(feedback)}>
                        {
                            settings.hasOwnProperty('status')
                            && feedback.hasOwnProperty('state')
                            && settings['status'].map(x => x['label']).includes(feedback['state']) ?
                                <span className="pastille" style={{
                                    backgroundColor: settings['status'].filter(x => x['label'] === feedback['state'])[0]['color'],
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px"
                                }}/> :
                                ''
                        }
                        <p className="feedback_infos">
                            {
                                manage_date(new Date(feedback['timestamp'])).toLocaleString() + ((feedback['types'] !== undefined && Object.keys(feedback['types']).length > 0) ? ' - ' + Object.keys(feedback['types']).map(x => utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List'][x])).join(', ') : '')
                            }
                        </p>
                        <img src={'./smiley/' + feedback['Predominant_Sentiment_Comprehend'] + '.svg'} alt=""/>
                        <p className="feedback_text">
                            {
                                feedback['text']
                            }
                        </p>
                        <FeedbackSteps Topic_Level1={feedback['Topic_Level1']} feedback={feedback} step={subFilters.step}/>
                    </div>
                )
            }
        </div>
    );
};

LastFeedbacks.propTypes = {
    feedbacks: PropTypes.array.isRequired,
    setShownFeedback: PropTypes.func.isRequired,
};

export default LastFeedbacks;
