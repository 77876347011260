import React, {useState, useEffect, useRef, useContext} from 'react';
import utils from "../../../utils/utils";
import texts from "../../../utils/texts";
import Chart from "chart.js";
import {FilterContext} from "../../../Contexts/FilterContext";
import Spinner from "../../Shared/Spinner";
import {ClassificationContext} from "../../../Contexts/ClassificationContext";

const SentimentEvolution = ({ subFilters }) => {
    const [sentiments, setSentiments] = useState([])
    const [step, setStep] = useState('Tous')
    const [loading, setLoading] = useState(false)
    const canvas = useRef(null);
    const { brand, startDate, endDate, classification, pointOfCollect } = useContext(FilterContext)
    const { steps } = useContext(ClassificationContext)


    let chart = null;

    useEffect(() => {
        if (sentiments && step) {
            let ctx = canvas.current.getContext('2d');
            console.log(sentiments)
            const dataSentimentsEvolution = utils.aggregateSentimentsPerTimePerTopics(sentiments, step)

            const data = {
                datasets: [
                    {
                        type: 'line',
                        label: utils.getLanguage(texts['Visualisations']['Suivi de la satisfaction des clients dans le temps']['Différence']),
                        borderColor: utils.gradients.turquoise,
                        fill: false,
                        data: dataSentimentsEvolution['difference'],
                        cubicInterpolationMode: 'monotone'
                    },
                    {
                        label: utils.getLanguage(texts['Visualisations']['Suivi de la satisfaction des clients dans le temps']['Commentaires positifs']),
                        backgroundColor: utils.gradients.blue,
                        data: dataSentimentsEvolution['positive_comments_count'],
                        cubicInterpolationMode: 'monotone'
                    }, {
                        label: utils.getLanguage(texts['Visualisations']['Suivi de la satisfaction des clients dans le temps']['Commentaires négatifs']),
                        backgroundColor: utils.gradients.red,
                        data: dataSentimentsEvolution['negative_comments_count'],
                        cubicInterpolationMode: 'monotone'
                    },]
            }

            chart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    maintainAspectRatio:false,
                    legend: {
                        labels: {
                            fontColor: 'rgb(150,150,150)'
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                displayFormats: {
                                    day: 'DD/MM/YYYY',
                                    hour: 'DD/MM/YYYY'
                                },
                                min: new Date(startDate),
                                max: new Date(endDate)
                            },
                            gridLines: {
                                color: 'rgb(90, 90, 90)',
                            },
                            distribution: 'linear',
                            stacked: true,
                        }],
                        yAxes: [{
                            gridLines: {
                                color: 'rgb(90, 90, 90)',
                            },
                        }]
                    }
                }
            });

            chart.update()
            return () => {
                if (chart!==null) {
                    chart.destroy()
                }
            };
        }

    }, [sentiments, step])

    useEffect(() => {
        if (subFilters.hasOwnProperty('step')) {
            setStep(subFilters['step'])
        }
    }, [subFilters])

    useEffect(() => {
        getSentimentPerTimePerTopics()
            .then(data => {
                setSentiments(data)
            })
    },  [
        startDate,
        endDate,
        classification,
        pointOfCollect,
        brand
    ])

    const getSentimentPerTimePerTopics = async () => {
        setLoading(true)
        const data = await utils.fetchSentimentPerTimePerTopics({
            brand,
            classification,
            endDate,
            poc: pointOfCollect,
            startDate,
            "number_of_points": 20
        })
        setLoading(false)
        return data
    }

    return (
        <div className="card graph position-relative">
            {
                loading && <Spinner/>
            }
            <p>{utils.getLanguage(texts['Visualisations']['Suivi de la satisfaction des clients dans le temps']['title'])}</p>
            <div className="filters">
                <span>
                    {classification.length > 0 ? classification : utils.getLanguage(texts['global']['Sujet'])}  :
                    <select className="topic_select" id='sent_evol_flt' title={utils.parseTopicName(step)} value={step} onChange={e => setStep(e.target.value)}>
                    {
                        ['Tous'].concat(steps).map((val, id) =>
                            <option value={val} key={id}>{utils.parseTopicName(val)}</option>
                        )
                    }
                    </select>
                </span>
            </div>
            <div className="graph-canvas">
                <canvas id="sentiment_evolution" ref={canvas}/>
            </div>
        </div>
    )
};

SentimentEvolution.propTypes = {

};

export default SentimentEvolution;
