import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import utils from "./utils/utils";
import "./dropdown.css";
import Header from "./components/Header";
import { UserContext } from "./Contexts/UserContext";
import Authenticated from "./components/Middlewares/Authenticated";
import { FilterContext } from "./Contexts/FilterContext";
import Modules from "./components/Modules/Modules";
import { ClassificationContext } from "./Contexts/ClassificationContext";
import OpenReplay from "@openreplay/tracker";

const tracker = new OpenReplay({
  projectKey: "Sl8iyfkDBJjRlHnG3LO7",
  onStart: ({ sessionID }) =>
    console.log("OpenReplay tracker started with session: ", sessionID),
  handleError: (error) => console.log("🔴 OpenReplay error: ", error),
});

tracker.start();

const crypto = require("crypto");

function getUrlVars() {
  const vars = {};
  window.location.href.replace(
    /[#&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

function App() {
  const [startDate, setStartDate] = useState(
    new Date().getTime() - 1000 * 30 * 24 * 60 * 60
  );
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [userBrands, setUserBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [username, setUsername] = useState("");
  const [settings, setSettings] = useState({});
  const [classifications, setClassifications] = useState([]);
  const [classification, setClassification] = useState("");
  const [pointOfCollect, setPointOfCollect] = useState([]);
  const [steps, setSteps] = useState([]);
  let url_brand = "";

  useEffect(() => {
    if (brand == "sncf_est") {
      window.location.replace("https://dashboard.beta.alloreview.com/sncf_est");
    }
  }, [brand]);

  useEffect(() => {
    // document.querySelector("#rdts1_trigger > ul > li > input").placeholder = 'Filtrer ...'
    if (getUrlVars()["state"] && getUrlVars()["state"] !== "undefined") {
      const url_vars = decodeURI(getUrlVars()["state"]);
      for (const v of url_vars.split("-")) {
        const [nm, vl] = v.split("=");
        if (nm === "startdate") {
          setStartDate(parseInt(vl));
        }
        if (nm === "enddate") {
          setEndDate(parseInt(vl));
        }
        if (nm === "brand") {
          url_brand = vl;
        }
      }
    }

    async function getUserData() {
      const userData = await utils.fetchUserData({});
      // console.log(userData)
      if (userData.hasOwnProperty("brands")) {
        const selectedBrand =
          userData.brands.includes(url_brand) ||
          userData.brands.includes("__admin__")
            ? url_brand
            : userData.brands[0];
        setUserBrands(userData.brands);
        setUsername(userData.user_id);
        setBrand(selectedBrand);

        function uuidv4(x) {
          const cr = crypto.createHash("sha256");
          cr.update(x);
          return cr.digest("hex");
        }
        tracker.setUserID(userData.user_id);
        // window.analytics.identify(uuidv4(userData.user_id), {
        //     "username": userData.user_id,
        //     "displayName": userData.user_id,
        //     "brand": selectedBrand
        // })
      }
    }

    getUserData();
  }, []);

  return (
    <Authenticated>
      <div className="App">
        <UserContext.Provider value={{ username, settings, setSettings }}>
          <FilterContext.Provider
            value={{
              brand,
              startDate,
              setStartDate,
              endDate,
              setEndDate,
              classification,
              setClassification,
              pointOfCollect,
              setPointOfCollect,
            }}
          >
            <ClassificationContext.Provider
              value={{ classifications, steps, setClassifications, setSteps }}
            >
              <Header />
            </ClassificationContext.Provider>
          </FilterContext.Provider>
        </UserContext.Provider>

        <UserContext.Provider value={{ username, settings }}>
          <FilterContext.Provider
            value={{
              brand,
              startDate,
              setStartDate,
              endDate,
              setEndDate,
              classification,
              pointOfCollect,
            }}
          >
            <ClassificationContext.Provider value={{ classifications, steps }}>
              <Modules />
            </ClassificationContext.Provider>
          </FilterContext.Provider>
        </UserContext.Provider>
      </div>
    </Authenticated>
  );
}

export default App;
