import React, { useState, useEffect, useContext } from 'react';
import utils from '../../../utils/utils';
import texts from '../../../utils/texts';
import './NerCloud.css'
import {FilterContext} from "../../../Contexts/FilterContext";
import {ClassificationContext} from "../../../Contexts/ClassificationContext";
import Spinner from "../../Shared/Spinner";


function NerCloud({subFilters}) {

    const [type, setType] = useState('ALL');
    const [step, setStep] = useState('Tous');
    const [clusterInput, setClusterInput] = useState('');
    const { steps } = useContext(ClassificationContext);
    const [cloudData, setCloudData] = useState([])
    const [maxSize, setMaxSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const { brand, startDate, endDate, classification, pointOfCollect} = useContext(FilterContext)

    useEffect(() => {
        if (subFilters.hasOwnProperty('step')) {
            setStep(subFilters['step'])
        }
        if (subFilters.cluster) {
            setClusterInput(subFilters.cluster)
        }
    }, [subFilters])

    function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const getRandomColor = () => {
        return 'rgb(' + (100 + Math.floor(Math.random() * 155)) + ', ' + (100 + Math.floor(Math.random() * 155)) + ', ' + (100 + Math.floor(Math.random() * 155)) + ')'
    }

    const types = [
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['DATE']), 'DATE'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['PERSON']), 'PERSON'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['COMMERCIAL_ITEM']), 'COMMERCIAL_ITEM'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['LOCATION']), 'LOCATION'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['EVENT']), 'EVENT'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['ORGANIZATION']), 'ORGANIZATION'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['TITLE']), 'TITLE'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['QUANTITY']), 'QUANTITY'],
        [utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['OTHER']), 'OTHER'],
        ["Product Group", "Product Group"],
        ["Commercial Reference: Product Name", "Commercial Reference: Product Name"],
    ];

    useEffect(() => {
        async function getData(setter) {
            setLoading(true)
            await utils.fetchNameEntitiesCloud({
                brand, startDate, endDate, classification, poc: pointOfCollect, step,  cluster: clusterInput
            }).then((data) => {
                setLoading(false)
                setter(shuffle(data))
            })
        }
        getData(setCloudData)

    }, [brand, startDate, endDate, classification, pointOfCollect, step, clusterInput])

    useEffect(() => {
        if (cloudData.length) {
            let tmp = 0
            filterData().forEach(data => {
                tmp = Math.max(tmp, data.count)
            })
            setMaxSize(tmp)
        }
    }, [cloudData, type])

    const filterData =  function() {
        return (cloudData instanceof Array ? cloudData : []).filter(data => {
            if (type === 'ALL') return true
            else {
                return data._id.type === type
            }
        })
    }



    return (
        <div className="card graph position-relative">
            {
                loading && <Spinner/>
            }
            <p>{utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']['title'])}</p>

            <div className="filters">
                <span>
                    {utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Type d'entité"])} :
                    <select onChange={e => setType(e.target.value)}>
                    {
                        [['Tout', 'ALL']].concat(types).map((val, id) =>
                            <option value={val[1]} key={id}>{val[0]}</option>
                        )
                    }
                    </select>
                </span>
                <span>
                    {classification.length > 0 ? classification : utils.getLanguage(texts['global']['Sujet'])}  :
                    <select className="topic_select" title={utils.parseTopicName(step)} value={step} onChange={e => setStep(e.target.value)}>
                    {
                        ['Tous'].concat(steps).map((val, id) =>
                            <option value={val} key={id}>{utils.parseTopicName(val)}</option>
                        )
                    }
                    </select>
                </span>
                <span>
                    Cluster  : <input id='' type="text" readOnly={true} value={clusterInput}/>
                    <span style={{color:'white', cursor: 'pointer'}} onClick={() => setClusterInput('')}>
                        X
                    </span>
                </span>
            </div>
            <div className="ner_cloud">
                    {
                        filterData().map((data, i) => (
                            <p className="ner_word tooltip" key={i} style={{fontSize: (50*data.count/maxSize + 14) + 'px', color: getRandomColor()}}>
                                {data._id.named_entity}
                                <span className='tooltiptext'>{data.count}</span>
                            </p>
                        ))
                    }
            </div>
        </div>
    )
}

export default NerCloud;
