import React from 'react';
import PropTypes from 'prop-types';
import utils from "../../../../../utils/utils";
import texts from "../../../../../utils/texts";

const OldOne = ({ feedback }) => {

    return (
        <div className="hilighted_feedback">
            <p width='90%' className="main_text" dangerouslySetInnerHTML={{__html: (() => {
                    if (!feedback['tagged_transcript']) {
                        return " "
                    } else {
                        return feedback['tagged_transcript'].replace(/<.{1,2}>/g, '').replace(/<\/.{1,2}>/g, '').replace(/SPEAKER/g, '<br/>SPEAKER')
                    }
                })()}}>
            </p>
            {
                //Corriger !!!
                [[/<4>/g, '<span class="hlgt_3">'], [/<3>/g, '<span class="hlgt_3">'], [/<2>/g, '<span class="hlgt_2">'], [/<1>/g, '<span class="hlgt_1">'], [/<-1>/g, '<span class="hlgt_m1">'], [/<0>/g, '<span class="hlgt_m1">'], [/<-2>/g, '<span class="hlgt_m2">'], [/<-3>/g, '<span class="hlgt_m3">'], [/<-4>/g, '<span class="hlgt_m3">']]
                    .map((val, id) => {
                        return (
                            <p key={id} className="highlight_text" dangerouslySetInnerHTML={{ __html: (() => {
                                    if (!feedback['tagged_transcript']) {
                                        return " "
                                    } else {
                                        return feedback['tagged_transcript']
                                            .replace(val[0], val[1])
                                            .replace(/<\/.{1,2}>/g, '</span>')
                                            .replace(/<.{1,2}>/g, '').replace(/SPEAKER/g, '<br/>SPEAKER')
                                    }
                                })()}}>
                            </p>
                        )
                    })}
            {
                (() => {
                    if (feedback['text'] !== feedback['text_original']){
                        return (
                            <>
                                <p style={{opacity: 0.6, marginLeft: '40px'}}>
                                    {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Texte original'])}
                                </p>
                                <p width='90%' className="main_text">
                                    {feedback['text_original']}
                                </p>
                            </>
                        )
                    }
                })()
            }
        </div>
    )
};

OldOne.propTypes = {
    feedback: PropTypes.object.isRequired
};

export default OldOne;
