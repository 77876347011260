import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react';
import utils from "../../../../utils/utils";
import texts from "../../../../utils/texts";
import FeedbackStatus from "../FeedbackStatus";
import {UserContext} from "../../../../Contexts/UserContext";
import FeedbackSteps from "../FeedbackSteps";
import HighlightedFeedback from "../HighlightedFeedback";
import {FilterContext} from "../../../../Contexts/FilterContext";
import Comments from "../Comments"
import html2canvas from 'html2canvas';
import { savePDF } from '@progress/kendo-react-pdf';


const FeedbacksDetails = ({ feedback, setFeedback, subFilters, feedbacks, setFeedbacks }) => {

    const [stepHover, setStepHover] = useState(false)
    const [audioUrl, setAudioUrl] = useState("")
    const { settings } = useContext(UserContext)
    const { brand } = useContext(FilterContext)
    const [imgLink, setImgLink] = useState("")
    const audioPlayer = useRef(null)
    const feedbackElem = useRef(null)

    useEffect(() => {
        setAudioUrl('')
        if (feedback.id) {
            getAudioUrl().then(audioUrl => {
                setAudioUrl(audioUrl)
            })
        }
        if (feedbackElem.current) {
            printDocument()
        }
    }, [feedback])

    const getAudioUrl = async function() {
        return await utils.fetchAudioPresignedUrl(feedback.audio || feedback.id, brand)
    }

    const manage_date = date => {
        date.setHours(date.getHours())
        return date
    }

    const copyFunction = () => {
        utils.CopyToClipboard(feedbackElem.current)
    }

    const delete_feedback = (id, brand) => {
        var confirmation = window.confirm("Êtes-vous sur de vouloir supprimer ?");
        if (!confirmation) {
            return true
        }
        console.log('OK')
        utils.deleteFeedback(id, brand).then(
            () => {
                let new_feedbacks = feedbacks.filter(
                    x => x['id'] !== id
                )
                setFeedback({})
                setFeedbacks(new_feedbacks)
            }
        )
    }

    const printDocument = () => {
        const input = feedbackElem.current;
        input.style.backgroundColor = 'rgb(42, 44, 71)';
        input.style.margin = '30px !important';
        input.style.borderRadius = '20px';
        input.classList.add("screenshot");
        html2canvas(input, {
            backgroundColor: null,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // Save image
            const link = document.createElement('a');
            link.href = imgData;
            setImgLink(imgData)
        })
        input.style.backgroundColor = '';
        input.style.margin = '';
        input.style.borderRadius = '';
        input.classList.remove("screenshot");
    }

    const printDocument2 = () => {
        const input = feedbackElem.current;
        input.style.backgroundColor = 'rgb(42, 44, 71)';
        input.style.margin = '30px !important';
        input.style.borderRadius = '20px';
        const createPdf = (html) => {
            savePDF(html, {
              paperSize: 'Letter',
              fileName: 'form.pdf',
              margin: 3
            })
          }
        createPdf(input,{
            keepTogether: ".position-relative",
        }, () => {
            input.style.backgroundColor = '';
            input.style.margin = '';
            input.style.borderRadius = '';
        })
    }

    return (
        feedback && Object.keys(feedback).length > 0 &&
        <div ref={feedbackElem}>
        <div className="position-relative">
            <div className="feedback_infos_div">
                <p className="feedback_infos" style={{fontSize: '14px', margin: '15px'}}>
                    {
                        manage_date(new Date(feedback['timestamp'])).toLocaleString() + ((feedback['types'] !== undefined && Object.keys(feedback['types']).length > 0) ? ' - ' + Object.keys(feedback['types']).map(x => utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List'][x])).join(', ') : '')
                    }
                    <span onClick={() => delete_feedback(feedback['id'], feedback['brand'])} className="option_button">
                        Supprimer
                    </span>
                    <span onClick={copyFunction} className="option_button">Copier</span>
                    <span><a href={imgLink} download="image.png" className="option_button">Image png</a></span>
                </p>
                {
                    settings.hasOwnProperty('status') && <FeedbackStatus feedback={feedback} feedbacks={feedbacks} setFeedbacks={setFeedbacks} setFeedback={setFeedback}/>
                }
            </div>

            <HighlightedFeedback feedback={feedback} stepHover={stepHover}/>

            <div id="hilight_legend">
                <div id="hilight_grd"></div>
                <div id="hilight_legend_txt">
                    <p>Négatif</p>
                    <p>Positif</p>
                </div>
            </div>
            <p/>
            <FeedbackSteps Topic_Level1={feedback['Topic_Level1']} feedback={feedback} handleHover={setStepHover} step={subFilters.step}/>
            <p/>
            {
                feedback.hasOwnProperty('email') && feedback['email'] !== undefined ? <p>Contact : {
                    feedback['email'].includes('@') ? <a style={{color: "lightblue"}}
                                                        href={"mailto:" + feedback['email']}>{feedback['email']}</a> :
                        <a style={{color: "lightblue"}} href={"tel:" + feedback['email']}>{feedback['email']}</a>
                }</p> : ""
            }
            <div className="metadata">
                {
                    (() => {
                        const metadatas = []
                        if (settings && settings.hasOwnProperty("feedback_detail") && settings["feedback_detail"].hasOwnProperty("to_display")) {
                            for (const k in settings["feedback_detail"]["to_display"]) {
                                if (feedback.hasOwnProperty(k) && feedback[k] != "nan") {
                                    metadatas.push(
                                        <p key={k}><span
                                            className="metadata_label">{settings["feedback_detail"]["to_display"][k]}</span>{String(feedback[k])}
                                        </p>
                                    )
                                }
                                else if (k.includes("custom:") && feedback.hasOwnProperty('custom') && feedback['custom'].hasOwnProperty(k.split(':')[1]) && feedback['custom'][k.split(':')[1]] != "nan") {
                                    metadatas.push(
                                        <p key={k}><span
                                            className="metadata_label">{settings["feedback_detail"]["to_display"][k]}</span>{feedback['custom'][k.split(':')[1]]}
                                        </p>
                                    )
                                }
                            }
                        }
                        return metadatas
                    })()
                }
            </div>
            <p/>
            {
                audioUrl.length > 2 ? <audio controls id='audio_feedback' src={audioUrl} ref={audioPlayer}/> : ''
            }
            <Comments feedback={feedback}/>
            <span style={{
                position: "absolute",
                bottom: "0",
                opacity: "0",
                fontSize: "6px"
            }}>{feedback['id']}</span>
        </div>
        </div>
    );
}

FeedbacksDetails.propTypes = {
    feedback: PropTypes.object.isRequired,
    subFilters: PropTypes.object.isRequired,
};

export default FeedbacksDetails;
