import React, { useEffect, useState, useContext } from 'react';
import utils from "../../../utils/utils";
import texts from "../../../utils/texts";
import { FilterContext } from "../../../Contexts/FilterContext";
import {UserContext} from "../../../Contexts/UserContext";
import Spinner from "../../Shared/Spinner";



const Kpis = () => {
    const { brand, startDate, endDate, classification, pointOfCollect} = useContext(FilterContext)
    const [filters, setFilters] = useState({
        brand: brand,
        startDate: startDate,
        endDate: endDate,
        classification: classification,
        pointOfCollect: pointOfCollect
    })
    const { settings } = useContext(UserContext)
    const [kpis_to_display, setKpis_to_display] = useState(settings['kpis'] || ['nb_total', 'nb_positive', 'nb_negative', 'nps', 'last_24h'])

    useEffect(() => {
        setFilters({
            brand: brand,
            startDate: startDate,
            endDate: endDate,
            classification: classification,
            poc: pointOfCollect
        })
    }, [brand, startDate, endDate, classification, pointOfCollect])

    useEffect(() => {
        setKpis_to_display(settings['kpis'] || ['nb_total', 'nb_positive', 'nb_negative', 'nps', 'last_24h'])
    }, [settings])

    return (
        <div className="position-relative">
            <p>{utils.getLanguage(texts['KPIs title'])}</p>
                <div id='kpi_box'>
                    {
                        kpis_to_display.map(
                            x => <KPI id={x} filters={filters}/>
                        )
                    }
                </div>
        </div>
    );
};

const KPI = ({id, filters}) => {
    const [loading, setLoading] = useState(false)
    const [kpi, setKpi] = useState({
        "title": {
            "fr": "________________"
        },
        "value": "__"
    })
    
    useEffect(() => {
        getKpi()
            .then(kpis => {
                setKpi(kpis)
            })
    }, [filters, id])

    const getKpi = async () => {
        setLoading(true)
        const kpi = await utils.fetchKpi(filters, id)
        setLoading(false)
        return kpi
    }

    return <div className="card kpi position-relative overflow-hidden">
        {
            loading && <Spinner/>
        }
        <p className='kpi_title'>{utils.getLanguage(kpi['title'])}</p>
        <p className='kpi_value'>
            <span>
                {utils.getLanguage(kpi['value'])}
                {kpi['subvalue'] ? <span className="subtext">({utils.getLanguage(kpi['subvalue'])})</span> : ''}
            </span>
        </p>
    </div>
}


export default Kpis;
