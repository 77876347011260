const texts = {
    'title': {
        'en': "Customer experience monitoring dashboard",
        'fr': "Tableau de bord de suivi de l’expérience client",
    },
    'KPIs title': {
        'en': 'Key Performance Indicator (KPI)',
        'fr': 'Indicateurs clés de performance (KPI)',
    },
    'KPIs': {
        'Satisfaction': {
            'en': 'Satisfaction rate',
            'fr': 'Taux de satisfaction',
        },
        'Nombre total de commentaires sur la période': {
            'en': 'Total number of feedback for the selected period',
            'fr': 'Nombre total de commentaires sur la période',
        },
        'Taux de commentaires positifs': {
            'en': 'Rate of positive feedback',
            'fr': 'Taux de commentaires positifs',
        },
        'Taux de commentaires négatifs': {
            'en': 'Rate of negative feedback',
            'fr': 'Taux de commentaires négatifs',
        },
        'Net Promoter Score (NPS)': {
            'en': 'Net Promoter Score (NPS)',
            'fr': 'Net Promoter Score (NPS)',
        },
        'Nombre de commentaires des dernières 24h': {
            'en': 'Number of new feedback during the last 24h',
            'fr': 'Nombre de commentaires des dernières 24h',
        },
        'Mean rating': {
            'en': 'Mean rating',
            'fr': 'Note moyenne',
        }
    },
    'Visualisations title': {
        'en': 'Visualizations',
        'fr': 'Visualisations',
    },
    'Visualisations': {
        "Satisfaction des clients par étape de parcours": {
            'title': {
                'en': 'Client satisfaction per ',
                'fr': 'Satisfaction des clients par ',
            },
            'Différence': {
                'en': 'Difference',
                'fr': 'Différence',
            },
            'Commentaires positifs': {
                'en': 'Postitive feedback',
                'fr': 'Commentaires positifs',
            },
            'Commentaires négatifs': {
                'en': 'Negative feedback',
                'fr': 'Commentaires négatifs',
            },
            'Commentaires mitigés': {
                'en': 'Mixed feedback',
                'fr': 'Commentaires mitigés',
            },
            'Commentaires neutres': {
                'en': 'Neutral feedback',
                'fr': 'Commentaires neutres',
            },
            'Étapes de Parcours': {
                'en': 'Step of the customer journey',
                'fr': 'Étapes de Parcours',
            },
        },
        "Suivi de la satisfaction des clients dans le temps": {
            'title': {
                'en': 'Monitoring of customer satisfaction over time',
                'fr': 'Suivi de la satisfaction des clients dans le temps',
            },
            'Différence': {
                'en': 'Difference',
                'fr': 'Différence',
            },
            'Commentaires positifs': {
                'en': 'Postitive feedback',
                'fr': 'Commentaires positifs',
            },
            'Commentaires négatifs': {
                'en': 'Negative feedback',
                'fr': 'Commentaires négatifs',
            },
            'Étapes de Parcours': {
                'en': 'Step of the customer journey',
                'fr': 'Étapes de Parcours',
            },
        },
        "Nuage des expressions les plus représentatives": {
            'title': {
                'en': 'Most representative expressions cloud',
                'fr': 'Nuage des expressions les plus représentatives',
            },
            'Étapes de Parcours': {
                'en': 'Step of the customer journey',
                'fr': 'Étapes de Parcours',
            },
        },
        "Nuage des entités nommées": {
            'title': {
                'en': 'Named entity cloud',
                'fr': "Nuage des entités nommées",
            },
            "Type d'entité": {
                'en': 'Named entity type',
                'fr': "Type d'entité",
            },
            "Types d'entités": {
                'DATE':{
                    'en': "Date",
                    'fr': "Date",
                },
                'PERSON':{
                    'en': "Person",
                    'fr': "Personne",
                },
                'COMMERCIAL_ITEM':{
                    'en': "Commercial item",
                    'fr': "Produit",
                },
                'LOCATION':{
                    'en': "Location",
                    'fr': "Lieu",
                },
                'EVENT':{
                    'en': "Event",
                    'fr': "Évènement",
                },
                'ORGANIZATION':{
                    'en': "Organization",
                    'fr': "Organisation",
                },
                'TITLE':{
                    'en': "Title",
                    'fr': "Titre",
                },
                'QUANTITY':{
                    'en': "Quantity",
                    'fr': "Quantité",
                },
                'OTHER':{
                    'en': "Other",
                    'fr': "Autre",
                },
            }
        },
        "Analyse détaillée des avis": {
            'title': {
                'en': 'Detailled analysis of the feedback',
                'fr': 'Analyse détaillée des avis',
            },
            'Étapes de Parcours': {
                'en': 'Step of the customer journey',
                'fr': 'Étapes de Parcours',
            },
            'Sentiment': {
                'en': 'Sentiment',
                'fr': 'Sentiment',
            },
            'Sentiment list': {
                'POSITIVE': {
                    'en': 'Positive',
                    'fr': 'Positif',
                },
                'NEGATIVE': {
                    'en': 'Negative',
                    'fr': 'Négatif',
                },
                'NEUTRAL': {
                    'en': 'Neutral',
                    'fr': 'Neutre',
                },
                'MIXED': {
                    'en': 'Mixed',
                    'fr': 'Mitigé',
                },
            },
            'Type': {
                'en': 'Type',
                'fr': 'Type',
            },
            'Statut': {
                'en': 'Status',
                'fr': 'Statut',
            },
            'Type List': {
                'Praises': {
                    'en': 'Praises',
                    'fr': 'Éloges'
                },
                'Suggestions': {
                    'en': 'Suggestions',
                    'fr': 'Suggestions'
                },
                'Questions': {
                    'en': 'Questions',
                    'fr': 'Questions'
                },
                'Problems': {
                    'en': 'Problems',
                    'fr': 'Problèmes'
                },
            },
            'Entités nommées': {
                'en': 'Named entity',
                'fr': 'Entités nommées',
            },
            'Classé par': {
                'en': 'Order by',
                'fr': 'Classé par',
            },
            "Classé par liste": {
                "Date": {
                    'en': 'Date',
                    'fr': 'Date'
                },
                "Pertinence": {
                    'en': 'Pertinence',
                    'fr': 'Pertinence'
                },
                "Plus positif": {
                    'en': 'Most positive',
                    'fr': 'Plus positif'
                },
                "Plus négatifs": {
                    'en': 'Most negative',
                    'fr': 'Plus négatif'
                },
            },
            'Appliquer les filtres': {
                'en': 'Apply filters',
                'fr': 'Appliquer les filtres',
            },
            'Exporter': {
                'en': 'Export',
                'fr': 'Exporter',
            },
            'Texte original': {
                'en': 'Original text',
                'fr': 'Texte original',
            },
        }
    },
    "Comments": {
        "Envoyer": {
            "en": "Send",
            "fr": "Envoyer"
        },
        "Commentaires": {
            "en": "Comments",
            "fr": "Commentaires"
        }
    },
    "global": {
        "Tous": {
            'en': 'All',
            'fr': "Tous"
        },
        "Tout": {
            'en': 'All',
            'fr': 'Tout'
        },
        "Sujet": {
            'en': 'Topic',
            'fr': 'Sujet'
        }
    }
}

export default texts