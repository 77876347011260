import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {FilterContext} from "../../../../Contexts/FilterContext";
import {UserContext} from "../../../../Contexts/UserContext";
import utils from "../../../../utils/utils";
import texts from "../../../../utils/texts";

const ExportFeedbacks = ({ extraFilters }) => {

    const [dataDownloadUrl, setDataDownloadUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const { brand, startDate, endDate, classification, pointOfCollect } = useContext(FilterContext)
    const downloadLink = useRef(null)
    const { settings } = useContext(UserContext)

    useEffect(() => {
        if (dataDownloadUrl.length) {
            downloadLink.current.click()
            setDataDownloadUrl('')
        }
        return () => {
            setDataDownloadUrl('')
        }
    }, [dataDownloadUrl])

    const handleExportButtonClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        const response = await utils.fetchExportData({
            brand,
            startDate,
            endDate,
            classification,
            poc: pointOfCollect,
            ...extraFilters
        }, settings["export"])
        if (response.hasOwnProperty('presigned_url')) {
            setDataDownloadUrl(response.presigned_url)
        }
        setLoading(false)
    }

    return (
        <span>
            <button id="export_button" className={`apply ${loading? 'disabled': ''}`} onClick={handleExportButtonClick} disabled={loading}>
                {
                    utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Exporter'])
                }
            </button>
            <a href={dataDownloadUrl} download='data.xlsx' ref={downloadLink}></a>
        </span>
    );
};

ExportFeedbacks.propTypes = {
    extraFilters: PropTypes.object.isRequired
};

export default ExportFeedbacks;
