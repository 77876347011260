import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import utils from "../../../../utils/utils";
import texts from "../../../../utils/texts";
import {FilterContext} from "../../../../Contexts/FilterContext";
import {UserContext} from "../../../../Contexts/UserContext";
import {ClassificationContext} from "../../../../Contexts/ClassificationContext";
import ExportFeedbacks from "../ExportFeedbacks";


const FeedbackFilters = ({ subFilters, nerList, setLoading, setFeedbacks, setSubFilters }) => {

    const { settings } = useContext(UserContext)
    const [topicInput, setTopicInput] = useState('Tous')
    const [sentimentInput, setSentimentInput] = useState('Tous')
    const [typeInput, setTypeInput] = useState('Tous')
    const [nerInput, setNerInput] = useState('Tous')
    const [orderByInput, setOrderByInput] = useState('timestamp')
    const [clusterInput, setClusterInput] = useState('')
    const [stateInput, setStateInput] = useState('Tous')

    const { steps } = useContext(ClassificationContext)
    const { brand, startDate, endDate, classification, pointOfCollect} = useContext(FilterContext)

    useEffect(() => {
        getFeedbacks().then(feedbacks => {
            console.log(feedbacks)
            setFeedbacks(feedbacks)
        })
    }, [
        brand, startDate, endDate, classification, pointOfCollect, topicInput, sentimentInput, typeInput, nerInput, orderByInput, clusterInput, stateInput
    ])

    useEffect(() => {
        if (subFilters.step) {
            setTopicInput(subFilters.step)
        }
        if (subFilters.cluster) {
            setClusterInput(subFilters.cluster)
        }
    }, [subFilters])

    const getFeedbacks = async function () {
        setLoading(true)
        console.log(typeInput)
        let feedbacks = await utils.fetchLastFeedbacks({
            brand,
            startDate,
            endDate,
            classification,
            poc: pointOfCollect,
            step: topicInput,
            sentiment: sentimentInput,
            type: typeInput,
            ner: nerInput,
            sort: orderByInput,
            cluster: clusterInput,
            state: stateInput
        })
        setLoading(false)
        return (feedbacks instanceof Array ? feedbacks : []).filter(
            x => x['text'] !== 'nan' && x['tagged_transcript'] !== 'nan'
        ).map((x) => Object.assign(x, {
                'Topic_Level1': Array.isArray(x['Topic_Level1']) ? x['Topic_Level1'] : []
            })
        )
    }

    const handleApplyFiltersButtonClick = async () => {
        getFeedbacks().then(feedbacks => {
            setFeedbacks(feedbacks)
        })
    }

    const clearClusterInput = () => {
        /*setSubFilters({
            ...subFilters,
            cluster: ''
        })*/
        setClusterInput('')
    }

    // TODO Vraiment pas ouf ca revoir => Mieux de prendre un paramêtre qui est le setter
    const handleInputChange = (e) => {
        switch (e.target.name) {
            case "topicInput":
                setTopicInput(e.target.value)
                break
            case "sentimentInput":
                setSentimentInput(e.target.value)
                break
            case "typeInput":
                setTypeInput(e.target.value)
                break
            case "nerInput":
                setNerInput(e.target.value)
                break
                case "orderByInput":
                    setOrderByInput(e.target.value)
                break
                case "stateInput":
                    setStateInput(e.target.value)
                break
        }
    }

    return (
        <div className="filters">
            <span>
                {(classification.length > 0 ? classification : "Sujet")}  :
                <select className="topic_select" id='' name='topicInput' value={topicInput} onChange={handleInputChange}>
                    {
                        ['Tous'].concat(steps).map((val, id) =>
                            <option title={utils.parseTopicName(val)} value={val} key={id}>
                                {
                                    utils.parseTopicName(val)
                                }
                            </option>
                        )
                    }
                </select>
            </span>
            <span>
                {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Sentiment'])} :
                <select id='' name='sentimentInput' value={sentimentInput} onChange={handleInputChange}>
                    {
                        [
                            ['Tous', utils.getLanguage(texts['global']['Tous'])],
                            ['POSITIVE', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Sentiment list']['POSITIVE'])],
                            ['NEGATIVE', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Sentiment list']['NEGATIVE'])],
                            ['NEUTRAL', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Sentiment list']['NEUTRAL'])],
                            ['MIXED', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Sentiment list']['MIXED'])]
                        ].map((val, id) =>
                            <option value={val[0]} key={id}>{val[1]}</option>
                        )
                    }
                </select>
            </span>
            <span>
                {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type'])} :
                <select id='' name='typeInput' value={typeInput} onChange={handleInputChange}>
                    {
                        [
                            ['Tous', utils.getLanguage(texts['global']['Tous'])],
                            ['Praises', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List']['Praises'])],
                            ['Problems', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List']['Problems'])],
                            ['Suggestions', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List']['Suggestions'])],
                            ['Questions', utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Type List']['Questions'])]
                        ].map((val, id) =>
                            <option value={val[0]} key={id}>{val[1]}</option>
                        )
                    }
                </select>
            </span>
            <span>
                {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Entités nommées'])} :
                <select id='' name="nerInput" value={nerInput} onChange={handleInputChange}>
                    {
                        Object.keys(nerList).map((val, id) =>
                            <optgroup key={id} label={Object.fromEntries([
                                ["Tous", utils.getLanguage(texts['global']['Tous'])],
                                ["PERSON", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['PERSON'])],
                                ["LOCATION", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['LOCATION'])],
                                ["ORGANIZATION", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['ORGANIZATION'])],
                                ["COMMERCIAL_ITEM", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['COMMERCIAL_ITEM'])],
                                ["EVENT", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['EVENT'])],
                                ["DATE", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['DATE'])],
                                ["QUANTITY", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['QUANTITY'])],
                                ["TITLE", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['TITLE'])],
                                ["OTHER", utils.getLanguage(texts['Visualisations']['Nuage des entités nommées']["Types d'entités"]['OTHER'])]]
                            )[val]}>
                                {
                                    nerList[val].map((val1, id1) => <option value={val1} key={id1}>{val1}</option>)
                                }
                            </optgroup>
                        )
                    }
                </select>
            </span>
            <span>
                {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Classé par'])} :
                <select id='' name="orderByInput" value={orderByInput} onChange={handleInputChange}>
                    {
                        [["timestamp", "Date"], ["pertinence", "Pertinence"], ["best", "Plus positif"], ["worst", "Plus négatif"]].map((val, id) =>
                            <option value={val[0]} key={id}>{val[1]}</option>
                        )
                    }
                </select>
            </span>
            {settings.status ? <span>
                {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Statut'])} :
                <select id='' name='stateInput' value={stateInput} onChange={handleInputChange}>
                    {
                        [
                            ['Tous', utils.getLanguage(texts['global']['Tous'])],
                            ...settings.status.map(
                                x => [x.label, x.label]
                            )
                        ].map((val, id) =>
                            <option value={val[0]} key={id}>{val[1]}</option>
                        )
                    }
                </select>
            </span> : ''}
            <span>
                Cluster  : <input id='' type="text" readOnly={true} value={clusterInput}/>
                <span style={{color:'white', cursor: 'pointer'}} onClick={clearClusterInput}>
                    X
                </span>
            </span>

            {/*<a className="apply" onClick={handleApplyFiltersButtonClick}>
                {
                    utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Appliquer les filtres'])
                }
            </a>*/}
            <ExportFeedbacks extraFilters={{
                step: topicInput,
                sentiment: sentimentInput,
                type: typeInput,
                ner: nerInput,
                sort: orderByInput,
                cluster: clusterInput
            }}/>
        </div>
    );
};

FeedbackFilters.propTypes = {
    subFilters: PropTypes.object.isRequired,
    nerList: PropTypes.object.isRequired,
    setFeedbacks: PropTypes.func.isRequired,
    setSubFilters: PropTypes.func.isRequired
};

export default FeedbackFilters;
