import { useState, useEffect } from "react";
//import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types";

const AWS = require("aws-sdk");
const $ = require("jquery");
AWS.config.region = "eu-west-2"; // Région
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-west-2:13d810d5-435e-453b-8cd4-c3ea362d4d89",
  Logins: {
    "cognito-idp.eu-west-2.amazonaws.com/eu-west-2_Bm4KP9CbL":
      getUrlVars()["id_token"],
  },
});

function getUrlVars() {
  const vars = {};
  window.location.href.replace(
    /[#&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

const Authenticated = ({ children }) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    //fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const url_params = getUrlVars();
    if (url_params["state"].includes("sncf_est")) {
      window.location.replace("https://dashboard.beta.alloreview.com/sncf_est");
    }

    AWS.config.credentials.refresh((a) => {
      console.log(
        "🔴",
        getUrlVars()["access_token"] || localStorage.getItem("token")
      );
      const b = new AWS.CognitoIdentityServiceProvider();
      b.getUser(
        {
          AccessToken:
            getUrlVars()["access_token"] || localStorage.getItem("token"),
        },
        function (err, data) {
          if (err) {
            window.location.replace(
              "https://auth.alloreview.com/login?client_id=193pbgh938lhpgjf5efeldifc5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" +
                window.location.origin +
                "&state=" +
                getUrlVars()["state"]
            );
          } else {
            console.log(data);
            // Put token in local storage
            if (getUrlVars()["access_token"] && getUrlVars()["id_token"]) {
              localStorage.setItem("token", getUrlVars()["access_token"]);
              localStorage.setItem("id_token", getUrlVars()["id_token"]);
              const url_params = getUrlVars();
              delete url_params["access_token"];
              delete url_params["id_token"];
              delete url_params["expires_in"];
              delete url_params["token_type"];
              const url_params_string = Object.keys(url_params)
                .map(function (key) {
                  return key + "=" + url_params[key];
                })
                .join("&");
              window.history.pushState(
                {},
                "",
                window.location.href.split("#")[0] + "#" + url_params_string
              );
            }
            window.Intercom("boot", {
              app_id: "niaq8d52",
              name: data["Username"], // Full name
              email: data["UserAttributes"].filter(
                (x) => x["Name"] == "email"
              )[0]["Value"], // Email address
            });
          }
        }
      );
      $.ajax({
        url: "https://vusa9b7co8.execute-api.eu-west-2.amazonaws.com/prod/dashboard",
        type: "GET",
        //cache: false,
        headers: {
          Authorization:
            getUrlVars()["id_token"] || localStorage.getItem("id_token"),
        },
        success: (response) => {
          window.intercomSettings = {
            app_id: "niaq8d52",
            name: "adrien", // Full name
          };
          setCanRender(true);
        },
        error: (err) => {
          window.location.replace(
            "https://auth.alloreview.com/login?client_id=193pbgh938lhpgjf5efeldifc5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" +
              window.location.origin +
              "&state=" +
              getUrlVars()["state"]
          );
          //console.warn(err)
        },
      });
    });
  }, []);

  return canRender && children;
};

Authenticated.propTypes = {};

export default Authenticated;
