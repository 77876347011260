import React from 'react'

console.log("🔴", window.localStorage.getItem("poc"), JSON.parse(window.localStorage.getItem("poc")))

export const FilterContext = React.createContext({
    brand: '',
    classification: "",
    classifications: [],
    startDate: (new Date()).getTime() - 1000 * 30 * 24 * 60 * 60,
    endDate: new Date().getTime(),
    pointOfCollect: [],
    setClassifications: () => {},
    setClassification: () => {},
    setStartDate: () => {},
    setEndDate: () => {},
    setPointOfCollect: () => {},
})
