import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FeedbackFilters from "./FeedbackFilters";
import LastFeedbacks from "./LastFeedbacks";
import FeedbackDetails from "./FeedbackDetails/";
import utils from "../../../utils/utils";
import Spinner from "../../Shared/Spinner";

const Feedbacks = ({ subFilters, setSubFilters }) => {

    const [feedbacks, setFeedbacks] = useState([])
    const [shownFeedback, setShownFeedback] = useState({})
    const [loading, setLoading] = useState(false)
    const [nerList, setNerList] = useState({"Tous": ["Tous"], "PERSON": [], "LOCATION": [], "ORGANIZATION": [], "COMMERCIAL_ITEM": [], "EVENT": [], "DATE": [], "QUANTITY": [], "TITLE": [], "OTHER": []})

    useEffect(() => {
        if (feedbacks.length) {
            setNerList(utils.listNer(feedbacks))
        }
    }, [feedbacks])

    return (

        <div className="card graph full_screen position-relative" id="feedbacks-container">

            {
                loading && <Spinner/>
            }

            <div id="detail_container">

                <div className="half">
                    <div className="last_50_flex">
                        <FeedbackFilters subFilters={subFilters} nerList={nerList} setFeedbacks={setFeedbacks} setSubFilters={setSubFilters} setLoading={setLoading}/>
                        <LastFeedbacks feedbacks={feedbacks} setShownFeedback={setShownFeedback} subFilters={subFilters}/>
                    </div>
                </div>
                <div className="half">
                    <FeedbackDetails feedback={shownFeedback} setFeedback={setShownFeedback} subFilters={subFilters} feedbacks={feedbacks}  setFeedbacks={setFeedbacks}/>
                </div>

            </div>

        </div>
    );
};

Feedbacks.propTypes = {
    subFilters: PropTypes.object.isRequired,
    setSubFilters: PropTypes.func.isRequired,
};

export default Feedbacks;
