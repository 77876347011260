import React from 'react';
import './spinner.css'

const Spinner = () => {

    return (
        <div className="spinner">
            <div>
                <div className="bounce1"/>
                <div className="bounce2"/>
                <div className="bounce3"/>
            </div>
        </div>
    );
};


export default Spinner;
