import React, {useState, useEffect, useRef, useContext} from 'react';
import Chart from 'chart.js'
import TopicCloud from './TopicCloud/TopicCloud';
import NerCloud from './NerCloud/NerCloud';
import utils from '../../utils/utils';
import texts from '../../utils/texts';
import './Modules.css';
import '../../App.css';
import Kpis from "./Kpis";
import CustomerJourney from "./CustomJourney";
import SentimentEvolution from "./SentiementEvolution";
import {UserContext} from "../../Contexts/UserContext";
import Feedbacks from "./Feedbacks";

function Modules() {

    const [subFilters, setSubFilters] = useState({step: 'Tous'})

    const { settings } = useContext(UserContext)

    const modules_to_display = settings['modules'] || ['CustomerJourney', 'SentimentEvolution', 'TopicCloud', 'NerCloud', 'FeedbacksDetails']

    const module_dictionary = {
        "CustomerJourney": (index) => <CustomerJourney setSubFilters={setSubFilters} subFilters={subFilters} key={index}/>,
        "SentimentEvolution": (index) => <SentimentEvolution subFilters={subFilters} key={index}/>,
        "TopicCloud": (index) => <TopicCloud subFilters={subFilters} setSubFilters={setSubFilters} key={index}/>,
        "NerCloud": (index) => <NerCloud key={index} subFilters={subFilters}/>,
        "FeedbacksDetails": (index) => <Feedbacks subFilters={subFilters} setSubFilters={setSubFilters} key={index}/>
    }

    Chart.defaults.global.defaultFontColor='rgb(200, 200, 200)';


    return (
        <div className="Modules">

            <Kpis/>
            <div>
                <p>{utils.getLanguage(texts['Visualisations title'])}</p>
            </div>

            {
                modules_to_display.map((module, index) => (
                    module_dictionary[module](index)
                ))
            }
        </div>
    );
}


export default Modules;
