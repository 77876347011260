import React, { useState, useEffect, useContext } from 'react';
import utils from '../../../utils/utils';
import texts from '../../../utils/texts';

import './TopicCloud.css'
import {FilterContext} from "../../../Contexts/FilterContext";
import {ClassificationContext} from "../../../Contexts/ClassificationContext";
import Spinner from "../../Shared/Spinner";

function TopicCloud({ setSubFilters, subFilters }) {
    const [step, setStep] = useState('Tous');
    const [cloudData, setCloudData] = useState([])
    const [subCloudData, setSubCloudData] = useState([])
    const [isSubCloud, setIsSubCloud] = useState(false)
    const [loading, setLoading] = useState(false)
    const { brand, startDate, endDate, classification, pointOfCollect } = useContext(FilterContext)
    const { steps } = useContext(ClassificationContext)

    const getColor = (sentiment) => {
        return {
            'POSITIVE': 'rgb(7, 166, 23)',
            'NEGATIVE': 'rgb(166, 6, 6)',
            'MIXED': '#F7BD4F',
            'NEUTRAL': 'rgb(150, 150, 150)'
        }[sentiment]
    }

    useEffect(
        () => {
            setIsSubCloud(false)
        },
        [step]
    )

    useEffect(() => {
        async function getData(setter) {
            setLoading(true)
            await utils.fetchTopicCloud({
                brand, startDate, endDate, classification, poc: pointOfCollect, topic: step === 'Tous'? 'all': step === 'Autre'? 'other': step
            }).then((data) => {
                setLoading(false)
                if (data.length > 0) {
                    data = data.filter(
                        x => x['cluster'] !== -1
                    )
                    console.log("💬 ", data)
                    // Sort data by cluster
                    data = data.sort((a, b) => {
                        return a['cluster'] - b['cluster']
                    })
                    setter(data)
                } else {
                    setter([])
                }
            })
        }
        if (brand.length > 0) {
            getData(setCloudData)
        }

    }, [
        brand,
        startDate,
        endDate,
        classification,
        pointOfCollect,
        step
    ])


    useEffect(() => {
        if (subFilters.step) {
            setStep(subFilters['step'])
        }
    }, [subFilters.step])

    let maxSize = 0;
    try {// Check if array
        (cloudData instanceof Array ? cloudData : []).forEach(val => {maxSize = Math.max(maxSize, val['count'])})
    } catch (error) {
        console.error(cloudData)
    }

    const handleTopicClick = (value) => {
        if (!isSubCloud) {
            setSubFilters({
                ...subFilters,
                cluster: step + '>>' + value['cluster']
            })
            setSubCloudData(value["top10text"])
            setIsSubCloud(true)
        }
    }

    const getFontSize = (value, values) => {
        // Remove first and last deciles of values
        // 1 - Sort values
        // 2 - Get the first and last deciles
        // 3 - Create new array without first and last deciles
        const sortedValues = values.sort((a, b) => a - b)
        const firstDecile = sortedValues[Math.floor(sortedValues.length / 10)]
        const lastDecile = sortedValues[Math.floor(sortedValues.length * 9 / 10)]
        const valuesWithoutFirstAndLastDeciles = sortedValues.filter(
            (val, index) => index !== 0 && index !== sortedValues.length - 1
        )
        value = Math.max(Math.min(value, lastDecile), firstDecile)
        return (40 - 10)*(value - firstDecile) / (lastDecile - firstDecile) + 10
    }

    function truncate(input, n) {
        if (input.length > n) {
           return input.substring(0, n) + '...';
        }
        return input;
     };

    return(
        <div className="card graph position-relative">
            {
                loading && <Spinner/>
            }
            <p>{utils.getLanguage(texts['Visualisations']['Nuage des expressions les plus représentatives']['title'])}</p>
            <div className="filters">
                <span>
                    {(classification.length > 0 ? classification : "Sujet")} :
                    <select className="topic_select" value={step} title={utils.parseTopicName(step)} onChange={e => setStep(e.target.value)}>
                    {
                        ['Tous'].concat(steps).concat(['Autre']).map((val, id) =>
                            <option value={val} key={id}>{utils.parseTopicName(val)}</option>
                        )
                    }
                    </select>
                </span>
                {isSubCloud ? <span style={{color:'white', cursor: 'pointer'}} onClick={
                    () => {
                        setIsSubCloud(false)
                    }
                }>
                    X
                </span> : ""}
            </div>
            <div className="topic_container" style={{overflowY: 'scroll'}}>
                    {cloudData.length > 0
                        ? (!isSubCloud ? cloudData : subCloudData).map((val, i) => (
                            <p className="topic" key={i} onClick={() => handleTopicClick(val)} style={{fontSize: getFontSize(val['count'], cloudData.map(
                                x => x['count']
                            )) + 'px', margin: "3px", color: getColor(val['sentiment'])}}>
                                {truncate(val['text'], 40)}
                            </p>
                        ))
                        : <p className="topic" style={{fontSize: '11px', color: 'grey', textTransform: 'none'}}>
                            Aucune expression représentatives
                        </p>
                    }
            </div>
        </div>
    )
}

export default TopicCloud;
