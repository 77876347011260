import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react';
import utils from "../../../../utils/utils";
import texts from "../../../../utils/texts";
import {UserContext} from "../../../../Contexts/UserContext";
import "./style.css"

const Comments = ({feedback}) => {
    const [newComment, setNewComment] = useState("")
    const [comments, setComments] = useState(feedback['comments'] || [])
    const sendButton = useRef()

    useEffect(() =>{
        setNewComment('')
        setComments(feedback['comments'] || [])
        utils.fetchComments({
            feedback_id: feedback._id,
            brand: feedback.brand
        }).then(
            x => setComments(x)
        )
    }, [feedback])

    const sendComments = () => {
        sendButton.current.style.display = "none"



        utils.sendComments({
            feedback_id: feedback._id,
            brand: feedback.brand,
            text: newComment
        }).then(
            x => {
                console.warn(x)
                sendButton.current.style.display = ""
                setNewComment("")
                return true
            }
        ).then(
            () => {
                utils.fetchComments({
                    feedback_id: feedback._id,
                    brand: feedback.brand
                }).then(
                    x => setComments(x)
                )
            }
        )
    }
    return(
        <div className="Comments">
            <p className="titleComments">{utils.getLanguage(texts['Comments']['Commentaires'])}</p>
            <textarea value={newComment} onChange={e => setNewComment(e.target.value)}></textarea>
            <span onClick={sendComments} ref={sendButton} className="commentSendButton">{utils.getLanguage(texts['Comments']['Envoyer'])}</span>
            <div className="commentsList">
                {
                    comments.map(
                        (x, i) => <div key={i} className="comment">
                            <p className="commentInfo">
                                {new Date(x["timestamp"]*1000).toLocaleDateString()} {new Date(x["timestamp"]*1000).toLocaleTimeString()} - {x["user"]}
                            </p>
                            <p className="commentText">{x["text"]}</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Comments