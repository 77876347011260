import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import utils from "../../../../utils/utils";
import {ClassificationContext} from "../../../../Contexts/ClassificationContext";

const FeedbackSteps = ({ Topic_Level1, feedback, handleHover, step }) => {

    const { steps } = useContext(ClassificationContext);

    Topic_Level1 = Topic_Level1.filter(
        x => steps.includes(x)
    )
    let line1 = []
    let line2 = []
    if (!step || step === 'Tous') {
        line1 = Topic_Level1.filter(
            x => !x.includes(' > ')
        )
        line2 = Topic_Level1.filter(
            x => !line1.includes(x)
        )
    } else {
        line1 = Topic_Level1.filter(
            x => x.includes(step)
        )
        line2 = Topic_Level1.filter(
            x => !line1.includes(x)
        )
    }

    handleHover = handleHover || ((x) => {});
    const getColor = snt => {
        if (snt != 0 && !snt) {
            return 'linear-gradient(180deg, rgba(80,80,80,1) 0%, rgba(29,29,29,1) 100%)'
        }
        if (snt >= 7) {
            return 'linear-gradient(180deg, rgba(7,166,23,1) 0%, rgba(6,107,12,1) 100%)'
        }
        if (snt <= 4) {
            return 'linear-gradient(180deg, rgba(166,7,7,1) 0%, rgba(119,6,6,1) 100%)'
        }
        return 'linear-gradient(180deg, rgba(189,199,6,1) 0%, rgba(130,122,8,1) 100%)'
    }

    const getImage = snt => {
        if (!snt) {
            return './smiley/NEUTRAL.svg'
        }
        if (snt >= 7) {
            return './smiley/POSITIVE.svg'
        }
        if (snt <= 4) {
            return './smiley/NEGATIVE.svg'
        }
        return './smiley/MIXED.svg'
    }

    return (
        <p className="feedback_step">
            {
                line1.map((val1, id) =>  {
                    return (<span onMouseEnter={() => handleHover(val1)} onMouseLeave={() => handleHover(false)} key={id} style={{
                        background: getColor(feedback['sentiment'][val1])
                    }} className='step'><img src={getImage(feedback['sentiment'][val1])}/>{utils.parseTopicName(val1)}</span>)
                })
            }
            {line1.length > 0 && line2.length > 0 ? <><br/><br/></> : ''}
            {
                line2.map((val1, id) =>  {
                    return (<span onMouseEnter={() => handleHover(val1)} onMouseLeave={() => handleHover(false)} key={id} style={{
                        background: getColor(feedback['sentiment'][val1])
                    }} className='step'><img src={getImage(feedback['sentiment'][val1])}/>{utils.parseTopicName(val1)}</span>)
                })
            }
        </p>
    )
};

FeedbackSteps.propTypes = {

};

export default FeedbackSteps;
