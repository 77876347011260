import React from 'react';
import PropTypes from 'prop-types';
import utils from "../../../../../utils/utils";
import texts from "../../../../../utils/texts";

const NewOne = ({ feedback, stepHover }) => {

    let text_splitted = feedback['text'];

    feedback['splitted_analysis'].forEach(x => {
        text_splitted = text_splitted.replace(x['text'], '|' + x['text'] + '|')
    });

    text_splitted = text_splitted.split('|')

    text_splitted = text_splitted.map(
        x => {
            const output = {
                text: x,
                topics: []
            }
            feedback['splitted_analysis'].forEach(
                elem => {
                    if (elem['text'] == x) {
                        output['sentiment'] = elem['sentiment']
                        output['topics'] = elem['topics']
                    }
                }
            )
            return output
        }
    )

    const sent_to_class = {
        'POSITIVE': 'hlgt_3',
        'MIXED': 'hlgt_1',
        'NEGATIVE': 'hlgt_m3',
        'NEUTRAL': 'hlgt_n',
    }

    return(
        <div className="hilighted_feedback">
            <p className="main_text">
                {
                    text_splitted.map(
                        (x, k) => {
                            return <span key={k} className={x['topics'].length > 0 ? sent_to_class[x['sentiment']] : ''} style={{opacity: stepHover && !x['topics'].includes(stepHover) ? '0.3' : '1'}}>{
                                x['text']
                            }</span>
                        }
                    )
                }
            </p>
            {
                (() => {
                    if (feedback['text'] !== feedback['text_original']){
                        return (
                            <>
                                <p style={{opacity: 0.6, marginLeft: '40px'}}>
                                    {utils.getLanguage(texts['Visualisations']['Analyse détaillée des avis']['Texte original'])}
                                </p>
                                <p width='90%' className="main_text">
                                    {feedback['text_original']}
                                </p>
                            </>
                        )
                    }
                })()
            }
        </div>
    )
};

NewOne.propTypes = {
    feedback: PropTypes.object.isRequired,
    stepHover: PropTypes.any
};

export default NewOne;
