import React from 'react';
import PropTypes from 'prop-types';
import NewOne from "./NewOne";
import OldOne from "./OldOne";

const HighlightedFeedback = ({ feedback, stepHover }) => {

    return (
        <div>
            {
                feedback.hasOwnProperty('splitted_analysis') && feedback.hasOwnProperty('splitted_analysis') !== undefined?
                    <NewOne feedback={feedback} stepHover={stepHover}/>:
                    <OldOne feedback={feedback} stepHover={stepHover}/>
            }
        </div>
    );
};

HighlightedFeedback.propTypes = {
    feedback: PropTypes.object.isRequired
};

export default HighlightedFeedback;
